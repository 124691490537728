import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will close today after the 4:30 class for Oaks.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`East will be closed on Saturday, Derby Day.  We will have one class
at the Ville at 10:00am.  All are invited so bring a friend!  There will
be no CrossFit Kids on Saturday.`}</em></strong></p>
    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Back Squat 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`Nasty Girls V2`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`50 Pistols, alternating`}</p>
    <p>{`7 Muscle Ups`}</p>
    <p>{`10 Hang Power Cleans (175/115)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      